.Data-Distribution .title {
  font-size: 30px;
  font-weight: bold; }

.Data-Distribution .main-block {
  background: #fff;
  width: 100%;
  margin-top: 10px;
  padding: 20px;
  border: 1px solid #e2e2e2; }
