.terminal-box {
  margin: 20px 0;
  padding: 20px;
  background: #fff;
  min-width: 999px; }
  .terminal-box .flex-box {
    display: flex; }
    .terminal-box .flex-box .form-list:first-child {
      margin-right: 20px; }
    .terminal-box .flex-box .form-input {
      vertical-align: top; }
    .terminal-box .flex-box > .form-list:last-child {
      display: initial; }
  .terminal-box .form-list .form-list {
    background-color: #fff; }
  .terminal-box .tc-15-filtrate-menu {
    width: 330px; }
  .terminal-box .select-address-box.pure-text-row > .form-input {
    padding-bottom: 0; }
  .terminal-box .select-address-box.pure-text-row .form-list {
    padding: 0;
    margin: 0; }
    .terminal-box .select-address-box.pure-text-row .form-list > li {
      float: left; }
      .terminal-box .select-address-box.pure-text-row .form-list > li:first-child, .terminal-box .select-address-box.pure-text-row .form-list > li:nth-child(2) {
        margin-right: 8px; }
      .terminal-box .select-address-box.pure-text-row .form-list > li .form-label {
        display: none; }
      .terminal-box .select-address-box.pure-text-row .form-list > li select {
        width: 105px;
        min-width: 105px; }
    .terminal-box .select-address-box.pure-text-row .form-list li:first-child .icon-valid-flag, .terminal-box .select-address-box.pure-text-row .form-list li:nth-child(2) .icon-valid-flag {
      display: none; }
  .terminal-box .customFields h4 {
    display: none; }
  .terminal-box .customFields .box {
    width: auto;
    display: block !important;
    margin-left: 0 !important;
    margin-bottom: 0 !important; }

.commonModal.saleArea {
  width: 770px !important;
  min-width: 770px !important; }

.blue {
  color: #1469FB;
  cursor: pointer;
  margin-right: 10px; }

.DistributorSetting-table-header, .DistributorSetting-table-row {
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  font-size: 12px; }
  .DistributorSetting-table-header > div, .DistributorSetting-table-row > div {
    height: 40px;
    line-height: 40px;
    color: #888;
    padding: 0 8px;
    font-weight: bold;
    flex-shrink: 0; }
    .DistributorSetting-table-header > div:last-child, .DistributorSetting-table-row > div:last-child {
      flex-grow: 1; }

.DistributorSetting-table-row {
  border-top: none; }
  .DistributorSetting-table-row > div {
    height: auto;
    line-height: initial;
    padding: 6px 8px;
    font-weight: normal; }
  .DistributorSetting-table-row ul.tc-15-filtrate-menu {
    width: 100%; }
  .DistributorSetting-table-row .tc-15-dropdown-link {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%; }

.DistributorSetting-addcolumn {
  padding: 8px;
  border: 1px solid #ddd;
  border-top: none; }

.MoreInfoTables .tc-15-dropdown.date-dropdown {
  width: 290px; }

.MoreInfoTables .tc-15-filtrate-menu {
  width: 100% !important; }

.MoreInfoTables .tc-15-dropdown-btn-style .tc-15-dropdown-link {
  width: 100%; }
