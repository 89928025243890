.single-code-query-tool-type .title {
  margin: 20px 0 10px 0;
  font-size: 28px; }

.single-code-query-tool-type .body .add {
  margin-bottom: 10px; }

.single-code-query-tool-type .body .tc-15-table-panel td .operation-span span {
  color: #006eff;
  cursor: pointer;
  margin-right: 10px; }
