.allotCodeConfiguration .title {
  margin-top: 10px;
  font-size: 28px; }

.allotCodeConfiguration .table {
  padding: 20px;
  margin: 10px 0px;
  background-color: #fff;
  border: 1px solid #e2e2e2; }

.allotCodeConfiguration .main-block {
  background: #fff;
  width: 100%;
  margin-top: 20px;
  padding: 20px;
  border: 1px solid #e2e2e2;
  min-height: 64px; }

.allotDataCenter .title {
  margin-top: 10px;
  font-size: 28px; }

.allotDataCenter .main-block {
  background: #fff;
  width: 100%;
  margin-top: 10px;
  padding: 20px;
  border: 1px solid #e2e2e2; }

.allotDataCenter .tc-15-dropdown-btn-style .tc-15-dropdown-link {
  width: 330px; }

.allotDataGet {
  font-size: 12px; }
  .allotDataGet .title {
    margin-top: 10px;
    font-size: 28px; }
  .allotDataGet .main-block {
    background: #fff;
    width: 100%;
    margin-top: 10px;
    padding: 20px;
    border: 1px solid #e2e2e2;
    min-height: 64px; }
  .allotDataGet .cursorp {
    cursor: pointer; }
  .allotDataGet .cblue {
    color: #006eff !important; }
  .allotDataGet .clear {
    clear: both; }
  .allotDataGet .tc-15-select {
    min-width: 130px !important; }
  .allotDataGet .inline {
    display: inline-block;
    float: left;
    margin-bottom: 20px; }
    .allotDataGet .inline .form-label label {
      display: none; }
    .allotDataGet .inline .form-input-help {
      float: left;
      margin-bottom: 0; }
    .allotDataGet .inline .icon-valid-flag {
      display: none; }
    .allotDataGet .inline .form-ctrl-label {
      display: block;
      height: 40px;
      line-height: 40px; }
    .allotDataGet .inline .tc-15-select,
    .allotDataGet .inline .tc-input-group {
      min-width: 0px; }
  .allotDataGet .line-block .check-box {
    margin-left: 15px; }
  .allotDataGet .line-block .form-unit .form-input-help {
    margin-left: 10px; }
  .allotDataGet .line-block .is-error .icon-valid-flag {
    display: none; }
  .allotDataGet .line-block .input {
    width: 55px;
    height: 30px;
    margin-left: 10px;
    margin-right: 10px;
    padding: 2px 2px;
    border: 1px solid #DDDDDD;
    font-size: 12px;
    text-align: center; }
  .allotDataGet .line-block .radio {
    font-size: 12px;
    color: #888888;
    display: inline-block; }
  .allotDataGet .line-block .itemClass {
    font-size: 12px;
    color: #888888 !important;
    display: inline-table; }
    .allotDataGet .line-block .itemClass .form-ctrl-label {
      margin-right: 10px; }
    .allotDataGet .line-block .itemClass .form-unit {
      color: #888888 !important; }

.allotTencentSafer {
  font-size: 12px; }
  .allotTencentSafer .title {
    margin-top: 10px;
    font-size: 28px; }
  .allotTencentSafer .main-block {
    background: #fff;
    width: 100%;
    margin-top: 10px;
    padding: 20px;
    border: 1px solid #e2e2e2; }
  .allotTencentSafer .cursorp {
    cursor: pointer; }
  .allotTencentSafer .cblue {
    color: #006eff !important; }
  .allotTencentSafer .clear {
    clear: both; }
  .allotTencentSafer .tc-15-select {
    min-width: 130px !important; }
  .allotTencentSafer .inline {
    display: inline-block;
    float: left;
    margin-bottom: 20px; }
    .allotTencentSafer .inline .form-label label {
      display: none; }
    .allotTencentSafer .inline .form-input-help {
      float: left;
      margin-bottom: 0; }
    .allotTencentSafer .inline .icon-valid-flag {
      display: none; }
    .allotTencentSafer .inline .form-ctrl-label {
      display: block;
      height: 40px;
      line-height: 40px; }
    .allotTencentSafer .inline .tc-15-select,
    .allotTencentSafer .inline .tc-input-group {
      min-width: 0px; }
  .allotTencentSafer .line-block .check-box {
    margin-left: 15px; }
  .allotTencentSafer .line-block .form-unit .form-input-help {
    margin-left: 10px; }
  .allotTencentSafer .line-block .is-error .icon-valid-flag {
    display: none; }
  .allotTencentSafer .line-block .input {
    width: 55px;
    height: 30px;
    margin-left: 10px;
    margin-right: 10px;
    padding: 2px 2px;
    border: 1px solid #DDDDDD;
    font-size: 12px;
    text-align: center; }
  .allotTencentSafer .line-block .radio {
    font-size: 12px;
    color: #888888;
    display: inline-block; }
  .allotTencentSafer .line-block .itemClass {
    font-size: 12px;
    color: #888888 !important;
    display: inline-table; }
    .allotTencentSafer .line-block .itemClass .form-ctrl-label {
      margin-right: 10px; }
    .allotTencentSafer .line-block .itemClass .form-unit {
      color: #888888 !important; }

.tencentSafeViewDialog {
  font-size: 12px; }
  .tencentSafeViewDialog .cursorp {
    cursor: pointer; }
  .tencentSafeViewDialog .cblue {
    color: #006eff !important; }
  .tencentSafeViewDialog .form-list {
    margin-bottom: 20px !important; }
  .tencentSafeViewDialog .line-block .check-box {
    margin-left: 15px; }
  .tencentSafeViewDialog .line-block .form-unit .form-input-help {
    margin-left: 10px; }
  .tencentSafeViewDialog .line-block .is-error .icon-valid-flag {
    display: none; }
  .tencentSafeViewDialog .line-block .input {
    width: 55px;
    height: 30px;
    margin-left: 10px;
    margin-right: 10px;
    padding: 2px 2px;
    border: 1px solid #DDDDDD;
    font-size: 12px;
    text-align: center; }
  .tencentSafeViewDialog .line-block .radio {
    font-size: 12px;
    color: #888888;
    display: inline-block; }
  .tencentSafeViewDialog .line-block .itemClass {
    font-size: 12px;
    color: #888888 !important;
    display: inline-table; }
    .tencentSafeViewDialog .line-block .itemClass .form-ctrl-label {
      margin-right: 10px; }
    .tencentSafeViewDialog .line-block .itemClass .form-unit {
      color: #888888 !important; }
  .tencentSafeViewDialog .box {
    padding: 0;
    border: 1px solid #ddd;
    border-bottom: none;
    width: 360px;
    margin-left: 41px;
    margin-right: 20px; }
    .tencentSafeViewDialog .box .box-head,
    .tencentSafeViewDialog .box .box-body {
      border-bottom: 1px solid #ddd;
      position: relative; }
      .tencentSafeViewDialog .box .box-head > div,
      .tencentSafeViewDialog .box .box-body > div {
        padding: 0 10px;
        width: 42.5%;
        vertical-align: middle;
        line-height: 48px;
        display: inline-block;
        min-height: 50px;
        vertical-align: top;
        color: #444;
        font-weight: bold;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap; }
      .tencentSafeViewDialog .box .box-head > div:last-child,
      .tencentSafeViewDialog .box .box-body > div:last-child {
        width: 15%; }
    .tencentSafeViewDialog .box .box-head2,
    .tencentSafeViewDialog .box .box-body2 {
      display: flex;
      justify-content: space-between; }
      .tencentSafeViewDialog .box .box-head2 > div,
      .tencentSafeViewDialog .box .box-body2 > div {
        flex: 1; }
    .tencentSafeViewDialog .box .box-head > div {
      height: 40px;
      line-height: 40px;
      color: #888;
      min-height: 40px; }
    .tencentSafeViewDialog .box .box-body:hover {
      background-color: #f7f7f7; }
    .tencentSafeViewDialog .box .box-body > div {
      font-weight: normal; }
    .tencentSafeViewDialog .box .is-error .form-ctrl-label,
    .tencentSafeViewDialog .box .is-error .form-input-help {
      line-height: 0;
      margin-bottom: 20px; }
    .tencentSafeViewDialog .box .form-input,
    .tencentSafeViewDialog .box .form-label,
    .tencentSafeViewDialog .box .form-output {
      padding-bottom: 0; }
    .tencentSafeViewDialog .box .tc-15-dropdown-btn-style .tc-15-dropdown-link {
      width: 130px; }
    .tencentSafeViewDialog .box .icon-valid-flag {
      display: none; }
    .tencentSafeViewDialog .box .tc-input-group {
      line-height: 28px; }

.enterprisesModal {
  font-size: 12px; }
  .enterprisesModal .nolabel {
    display: inline-block;
    padding: 0px 5px;
    vertical-align: middle; }
    .enterprisesModal .nolabel .form-label,
    .enterprisesModal .nolabel .form-input-help {
      display: none; }
    .enterprisesModal .nolabel .form-ctrl-label {
      margin-right: 0; }
  .enterprisesModal .nolabelnolabel .icon-valid-flag {
    display: none; }
  .enterprisesModal .nolabelnolabel .form-label {
    display: none; }

.CreatePoolPrize {
  width: 480px; }

.timeConfigForm .tc-15-dropdown,
.timeConfigForm .tc-15-input-text,
.timeConfigForm .tc-15-simulate-select,
.timeConfigForm .tc-15-select {
  width: 330px; }
