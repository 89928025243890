.blue {
  color: #1469FB;
  cursor: pointer;
  margin-right: 10px; }

.gray {
  color: #888;
  cursor: pointer;
  margin-right: 10px; }

.datadealer-search, .datadealer-search .tc-15-search-words {
  width: 190px; }

.dealer-actions .table-operate-btn {
  padding-right: 10px; }

.datadealer-form {
  margin-top: 20px;
  background: #fff;
  padding: 20px; }
  .datadealer-form .box {
    width: 763px;
    font-size: 12px;
    padding: 0;
    border: 1px solid #ddd;
    border-bottom: none; }
    .datadealer-form .box .box-head,
    .datadealer-form .box .box-body {
      border-bottom: 1px solid #ddd; }
      .datadealer-form .box .box-head > div,
      .datadealer-form .box .box-body > div {
        padding: 0 10px;
        width: 30%;
        vertical-align: middle;
        line-height: 48px;
        display: inline-block;
        min-height: 50px;
        vertical-align: top;
        color: #444;
        font-weight: bold;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap; }
      .datadealer-form .box .box-head > div:last-child,
      .datadealer-form .box .box-body > div:last-child {
        width: 10%; }
    .datadealer-form .box .box-head > div {
      height: 40px;
      line-height: 40px;
      color: #888;
      min-height: 40px; }
    .datadealer-form .box .box-body:hover {
      background-color: #f7f7f7; }
    .datadealer-form .box .box-body > div {
      font-weight: normal; }
    .datadealer-form .box .form-input,
    .datadealer-form .box .form-label,
    .datadealer-form .box .form-output {
      padding-bottom: 0; }
    .datadealer-form .box .is-error .form-ctrl-label,
    .datadealer-form .box .is-error .form-input-help {
      line-height: 0;
      margin-bottom: 20px; }
    .datadealer-form .box .icon-valid-flag {
      display: none; }
  .datadealer-form .areare {
    width: 300px; }
    .datadealer-form .areare .form-list {
      display: flex;
      justify-content: flex-start; }
      .datadealer-form .areare .form-list li .tc-15-select {
        width: 93px;
        min-width: 30px;
        margin-right: 10px; }
      .datadealer-form .areare .form-list li .form-label label {
        width: 90px; }
      .datadealer-form .areare .form-list > li:not(:nth-child(1)) .form-label {
        display: none; }
  .datadealer-form .tc-15-dropdown-link {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 95%; }
  .datadealer-form .btn-box {
    border-top: 1px solid #dddddd;
    padding-top: 10px; }
    .datadealer-form .btn-box .weak {
      margin-left: 10px; }
  .datadealer-form .customFields h4 {
    display: none; }

.commonModal.saleArea {
  width: 770px !important;
  min-width: 770px !important; }

.deptId-box {
  margin-top: 20px; }
  .deptId-box .ant-cascader-picker.antdCascader {
    width: 300px; }
  .deptId-box .form-label {
    width: 92px; }
  .deptId-box .form-unit {
    position: relative; }
    .deptId-box .form-unit .deptId-icon {
      border: none;
      width: 30px;
      min-width: 30px;
      height: 30px;
      display: block;
      position: absolute;
      right: 1px;
      top: 1px; }
    .deptId-box .form-unit .tc-15-select.deptId-icon:focus,
    .deptId-box .form-unit .tc-15-select.deptId-icon:hover {
      color: #000;
      background-color: #fff; }
  .deptId-box .is-error .ant-input {
    border-color: #E1504A;
    box-shadow: 0 0 0 0 transparent; }
  .deptId-box .is-error .deptId-icon {
    right: 41px; }
  .deptId-box .is-error .icon-valid-flag {
    width: 16px;
    height: 16px;
    margin-top: -10px; }

.SalesRelations-table-header, .SalesRelations-table-row {
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  font-size: 12px; }
  .SalesRelations-table-header > div, .SalesRelations-table-row > div {
    height: 40px;
    line-height: 40px;
    color: #888;
    padding: 0 8px;
    font-weight: bold;
    flex-shrink: 0; }
    .SalesRelations-table-header > div:last-child, .SalesRelations-table-row > div:last-child {
      flex-grow: 1; }

.SalesRelations-table-row {
  border-top: none; }
  .SalesRelations-table-row > div {
    height: auto;
    line-height: initial;
    padding: 6px 8px;
    font-weight: normal; }
  .SalesRelations-table-row ul.tc-15-filtrate-menu {
    width: 100%; }
  .SalesRelations-table-row .tc-15-dropdown-link {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%; }

.SalesRelations-addcolumn {
  padding: 8px;
  border: 1px solid #ddd;
  border-top: none; }
